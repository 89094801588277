export default {
  home: {
    title: 'EYEFIRE',
    description:
      'Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
    integrated_title1: 'On Premise',
    integrated_title2: 'Cloud Service',
    integrated_title3: 'Tích hợp',
    integrated_content1:
      'Triển khai trọn gói trên hạ tầng <br/> của đối tác, khách hàng',
    integrated_content2:
      'Sử dụng hạ tầng cloud server <br/> của EYEFIRE, triển khai nhanh chóng',
    integrated_content3:
      'MobileSDK, ServerSDK, APIs <br/> đáp ứng hầu hết các nhu cầu tích hợp',
    hotTech: 'Công nghệ nổi bật',
    solutions: 'Các giải pháp',
    solutions_des:
      'Giải pháp toàn diện, giải quyết các bài toán thực tế.</br> Có thể tuỳ biến đáp ứng mọi nhu cầu',
    product: 'SẢN PHẨM',
    product_sub:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
    facesdk:
      "<p>Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng,<br className='item-pc'/> các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK</p>",
    news: 'TIN TỨC',
    new_des:
      'Thông tin công nghệ, sản phẩm của EYEFIRE. Các sự kiện, hoạt động hợp tác, các thông báo và <br/> nhiều thông tin khác',

    field: 'CÁC LĨNH VỰC',
    field_sub: 'Công nghệ của EYEFIRE có thể ứng dụng trong mọi lĩnh vực',
    customer: 'Khách hàng và đối tác',
    customer_des:
      'EYEFIRE tự hào khi là đối tác của các doanh nghiệp, tổ chức hàng đầu',
    start: 'TÍCH HỢP VÀ TRIỂN KHAI',
    start_des:
      'Công nghệ nhận diện đa nền tảng, customize theo nhu cầu, linh động các hình thức triển khai',
    contact: 'Liên hệ để được tư vấn',

    prize_title: '<h1>TRUYỀN THÔNG <br /> BÁO CHÍ NÓI VỀ EYEFIRE</h1>',
  },
  layout: {
    vn: 'VN',
    el: 'EN',
    jp: 'JP',
    home: 'Trang chủ',
    technology: 'Công nghệ',
    product: 'Sản phẩm',
    solution: 'Giải pháp',
    sdk: 'SDK',
    job: 'Tuyển dụng',
    news: 'Tin tức',
    contact: 'Liên hệ',
    slogan:
      'Tiên phong trong các sản phẩm và giải <br className="item-sp"> pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh.',
    office: 'OFFICE',
    menu: 'Menu',
    adrHn: 'TRỤ SỞ',
    adrHcm: 'CHI NHÁNH HỒ CHÍ MINH',
    adrJp: 'CHI NHÁNH NHẬT BẢN',
    address1:
      'Tầng 3 tòa nhà Fafim A, Số 19 Nguyễn Trãi,</br> Phường Khương Trung, Quận Thanh Xuân, TP Hà Nội',
    address2:
      'Tầng 5, Toà nhà VTD, Số 17 Ho Van Hue,</br> Phường 9, Quận Phú Nhuận, TP Hồ Chí Minh',
    address3: '〒190-0023 <br />東京都立川市柴崎町3-8-5立川NXビル5F',
    rule1: 'Điều khoản dịch vụ',
    rule2: 'Chính sách bảo mật',
    keep: 'KẾT NỐI VỚI CHÚNG TÔI',
    copyright: 'Copyright © 2023EYEFIRE Corporation. All rights reserved.',
    contact_footer: 'LIÊN HỆ',
    content_contact_footer1:
      'Hãy gửi cho chúng tôi những thắc mắc, góp ý hoặc đề nghị hợp tác của bạn.',
    content_contact_footer2: 'Chúng tôi sẽ phản hồi trong thời gian sớm nhất!',
    button_contact: 'GỬI LIÊN HỆ HỢP TÁC',
    button_detail: 'Xem chi tiết',
    btn_send: 'Gửi thông tin',
    title_contact: 'Để lại thông tin liên hệ',
    content_contact1:
      'Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh.<br /> Vui lòng gửi email cho chúng tôi bất cứ lúc nào.',
    content_contact2: 'Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
  },
  sdk: {
    title_ft1: 'Face Recognition',
    title_ft2: 'Liveness',
    title_ft3: 'Mask Detection',
    content_ft1: 'Nhận diện khuôn mặt',
    content_ft2: 'Chống giả tuyệt đối',
    content_ft3: 'Phát hiện khẩu trang',
    slogan_sdk: 'FACE RECOGNITION SOFTWARE DEVELOPMENT KIT',
    title_sdk: 'EYEFIRE FACESDK',
    content_sdk1:
      'Đưa công nghệ nhận diện khuôn mặt vượt trội của EYEFIRE vào các dịch vụ của doanh nghiệp bạn',
    content_sdk2:
      'Đưa công nghệ nhận diện khuôn</br> mặt vượt trội của EYEFIRE vào các<br /> dịch vụ của doanh nghiệp bạn',
    button_try: 'Thử nghiệm ngay',
    title_big_box: 'EYEFIRE FaceSDK',
    content_big_box:
      'Đa nền tảng, dễ dàng tích hợp với các ứng dụng mobile và các hệ thống backend chạy trên server iOS, Android, Server',
    sdk1: 'iOS SDK',
    sdk2: 'Android SDK',
    sdk3: 'Server SDK',
    feature_title:
      'Đưa công nghệ nhận diện vào dịch vụ của doanh nghiệp bạn dễ dàng hơn bao giờ hết',
    face_title: 'Công nghệ nhận diện khuôn mặt',
    face_content:
      'EYEFIRE phát triển và làm chủ, đã được thử nghiệm và triển khai thực tế',
    feature_sdk_title: 'Các tính năng nổi bật của SDK',
    feature_sdk_content:
      'EYEFIRE phát triển và làm chủ, đã được thử nghiệm và triển khai thực tế',
    title_box1: 'Độ chính xác',
    title_box2: 'Tốc độ nhanh',
    title_box3: '1 ảnh mẫu',
    title_box4: 'An toàn và bảo mật',
    title_box5: 'Linh động mở rộng',
    content_box1:
      'Lên tới <span className="red-text">99.9%</span> trong điều kiện người dùng hoạt động bình thường, thậm chí có thể nhận diện ngay cả khi người dùng đeo kính, đội mũ',
    content_box2:
      'Tốc độ nhận diện dưới <span className="red-text">1s</span>. Trên thực tế về kỹ thuật, tốc độ nhận diện trung bình từ <span className="red-text">200-300ms</span> với tập dữ liệu từ 20-30k người dùng',
    content_box3:
      'Chỉ với <span className="red-text">1 ảnh</span> mẫu duy nhất làm dữ liệu huấn luyện, engine huấn luyện đã có thể xử lý để đảm bảo các yêu cầu về độ chính xác và tốc độ nhận diện.',
    content_box4:
      'Nhận diện chính xác, chống giả mạo bằng ảnh, bằng video hoặc bằng các hình thức khác',
    content_box5:
      'Công nghệ nhận diện khuôn mặt của EYEFIRE là công nghệ đa nền tảng, dễ dàng đồng bộ và tuỳ chỉnh theo nhu cầu doanh nghiệp bạn',
    tec_box_1: 'Tích hợp nhanh chóng',
    tec_box_2: 'Hiệu năng cao',
    tec_box_3: 'Công nghệ mới nhất',
    tec_box_4: 'Nhỏ gọn',
    tec_content_box1:
      'Kiến trúc và chức năng được thiết kế trực quan, tài liệu hướng dẫn rõ ràng giúp đội ngũ phát triển nắm bắt nhanh chóng',
    tec_content_box2:
      'FaceSDK của EYEFIRE là SDK hiệu năng cao, đảm bảo tốc độ nhận diện và đảm bảo độ chính xác trên hầu hết các thiết bị',
    tec_content_box3:
      'SDK luôn được đội ngũ phát triển của EYEFIRE nâng cấp với những công nghệ mới nhất',
    tec_content_box4:
      'Dung lượng SDK và model A.I nhỏ, được đóng gói, dễ dàng lưu trữ, phù hợp với các thiết bị di động',
  },
  tecnology: {
    hotTech: 'CÔNG NGHỆ NỔI BẬT',
    description:
      'Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
  },
  product: {
    product: 'SẢN PHẨM',
    product_des:
      'Sản phẩm tích hợp sẵn công nghệ của EyeFire, với nhiều tính năng vượt trội',
  },
  news: {
    news: 'TIN TỨC',
    new_des:
      'Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng trong video, hình ảnh',
    new_hot: 'TIN TỨC NỔI BẬT',
    new_hostlist:
      'Cùng điểm qua những tin tức đáng chú ý trong thời gian qua từ Eyefire nhé',
    list_hot: 'Các tin tức mới nhất',
    search: 'Tìm kiếm',
    relate: 'Tin liên quan',
    description:
      'EYEFIRE - Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
  },
  solution: {
    title_solution: 'CÁC GIẢI PHÁP',
    content_solution:
      '<p>Giải pháp toàn diện, giải quyết các bài toán thực tế. Có thể tuỳ biến đáp ứng mọi nhu cầu</p>',
  },

  text_button: {
    read_more: 'XEM THÊM',
    detail: 'Xem chi tiết',
    more: 'Tìm hiểu thêm',
    back: 'Trở lại',
  },

  contact: {
    title: 'Để lại thông tin liên hệ',
    description: ` <p className='desc'>Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh.<br /> Vui lòng gửi email cho chúng tôi bất cứ lúc nào.<br /> Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.</p>`,
    tcom_vn: 'TCOM Việt Nam',
    name: 'Họ tên',
    phone: 'Số điện thoại',
    tieu_de: 'Tiêu đề',
    purpose: 'Mục đích',
    content: 'Nội dung',
    submit: 'GỬI THÔNG TIN',
    member: 'HỆ THỐNG THÀNH VIÊN CỦA CHÚNG TÔI',
    form_name: 'Họ tên',
    form_mail: 'Email',
    form_phone: 'Số điện thoại',
    form_subject: 'Tiêu đề',
    form_purpose: 'Mục đích liên hệ',
    form_quotation: 'Báo giá',
    form_request: 'Yêu cầu tư vấn',
    form_cooperation: 'Hợp tác',
    form_recruitment: 'Tuyển dụng',
    form_orther: 'Khác',
    form_message: 'Nội dung liên hệ',
    form_file: 'Đính kèm file',
  },
  error: {
    title: 'Không tìm thấy trang',
    button: 'Quay về trang chủ',
  },
  seo: {
    home: {
      title: 'Trang chủ',
      description:
        'EYEFIRE - Tiên phong trong các sản phẩm và giải pháp dựa trên công nghệ nhận diện khuôn mặt, nhận diện đối tượng, nhận diện hành vi trong video, hình ảnh',
    },
    technology: {
      title: 'Công nghệ',
      description:
        'Công nghệ nhận diện khuôn mặt, nhận diện phương tiện giao thông, nhận diện hành vi bất thường, phát hiện sản phẩm lỗi, phát hiện chuyển động, phát hiện xâm nhập, hàng rào ảo, AIoT camera',
    },
    solution: {
      title: 'Giải pháp',
      description:
        'Giải pháp toàn diện, có thể tuỳ biến đáp ứng mọi nhu cầu của khách hàng bao gồm Giải pháp trường học thông minh, Giải pháp toà nhà thông minh, Giải pháp AIoT camera biến camera thường thành AI camera, Giải pháp tự động hoá dây chuyền sản xuất',
    },
    product: {
      title: 'Sản phẩm',
      description:
        'Thiết bị nhận diện chuyên dụng được EYEFIRE phát triển, tích hợp chức năng nhận diện khuôn mặt, nhận diện thẻ quẹt, kết nối với hệ thống mở cửa tự động, hệ thống cảnh báo an ninh giám sát',
    },
    news: {
      title: 'Tin tức',
      description:
        'Những tin tức, sự kiện nổi bật về hoạt động hợp tác kinh doanh, cũng như các hoạt động nội bộ của EYEFIRE',
    },
    sdk: {
      title: 'SDK',
      description:
        'Dễ dàng tích hợp công nghệ nhận diện khuôn mặt vào các ứng dụng, các hệ thống nghiệp vụ sẵn có thông qua EYEFIRE SDK đa nền tảng bao gồm iOS SDK, Android SDK, Server SDK',
    },
    contact: {
      title: 'Liên hệ',
      description:
        'Gửi thông tin để được tư vấn, báo giá, hoặc liên hệ hợp tác kinh doanh. Vui lòng gửi email cho chúng tôi bất cứ lúc nào. Chúng tôi sẵn sàng lắng nghe và hỗ trợ quý khách.',
    },
  },
  banner: {
    solution: 'Giải pháp',

    slide1: {
      title: 'Trường học thông minh',
      img_prize: 'img_prize_2023_vi.png',
      desc:
        'Tối ưu tất cả các hoạt động hằng ngày của nhà trường, đồng thời tạo ra kênh liên lạc thông suốt giữa nhà trường với phụ huynh học sinh.',
      img_desktop: 'img_banner_pc.png',
      img_mb: 'bannerMobile3.png',
    },
    slide2: {
      title: 'AIOT Camera',
      desc:
        'Giải pháp biến camera thường thành AI camera, quản lý và lưu trữ camera tập trung, nền tảng cloud, cho phép xem và phân tích hình ảnh dựa trên công nghệ trí tuệ nhân tạo.',
      img_desktop: 'solution.svg',
      img_mb: 'bannerMobile2.png',
    },
    slide3: {
      title: 'An toàn nơi làm việc',
      img_prize: 'img_prize_2024_vi.png',
      desc:
        'Phát hiện sản phẩm lỗi, quản lý chấm công thông minh, quản lý truy cập, bãi đỗ xe thông minh, AIoT camera (giám sát an ninh, cảnh báo xâm nhập trái phép, cảnh báo người đi vào khu vực nguy hiểm, cảnh báo tai nạn lao động, giám sát hoạt động của cán bộ nhân viên)',
      img_desktop: '',
      img_mb: 'Safety.png',
    },
    slide4: {
      title: 'Trường học thông minh',
      img_prize: 'img_prize_2024_vi.png',
      desc:
        'Tối ưu tất cả các hoạt động hằng ngày của nhà trường, đồng thời tạo ra kênh liên lạc thông suốt giữa nhà trường với phụ huynh học sinh.',
      img_desktop: 'Face.png',
      img_mb: 'Face.png',
    },
  },
  term: 'Điều khoản dịch vụ',
  policy: 'Chính sách bảo mật',
  office: 'TRỤ SỞ CHÍNH',
  contact_now: 'LIÊN HỆ NGAY',
};
